<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
          <el-form-item label='主键id' prop='id' :label-width="formLabelWidth" v-if="false">
            <el-input placeholder='请输入主键id' clearable autocomplete="off" v-model='form.id' size="small"  />
          </el-form-item>
          <el-form-item label='角色名称' prop='roleName' :label-width="formLabelWidth">
            <el-input placeholder='请输入角色名称' clearable autocomplete="off" v-model='form.roleName' size="small"  />
          </el-form-item>
          <el-form-item label='备注' prop='remark' :label-width="formLabelWidth">
            <el-input placeholder='请输入备注' clearable autocomplete="off" v-model='form.remark' size="small"  />
          </el-form-item>
          <el-form-item label='菜单权限' prop='remark' :label-width="formLabelWidth">
              <el-tree
                  :data="data"
                  show-checkbox
                  default-expand-all
                  node-key="id"
                  ref="tree"
                  highlight-current
                  :props="defaultProps">
              </el-tree>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertSysRole,updateSysRole,deleteSysRole,bacthDelSysRole,getAllMenu,getRoleMenulistById } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo,startLoading } from "@/util/util"
  export default {
    name: "user",
    data(){
      const columns = [
        {type: 'selection'},
        {prop: 'id',label: '主键id'},
        {prop: 'roleName',label: '角色名称'},
        // {prop: 'roleSign',label: '角色标识'},
        {prop: 'remark',label: '备注'},
        // {prop: 'userIdCreate',label: '创建用户id'},
        {prop: 'createTime',label: '创建时间'},
        {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
            type: 'input',
            id: 'roleName',
            label: '角色名称',
            el: {placeholder: '请输入角色名称', clearable: true},
          },
      ]
      return {
        columns,
        tableConfig: {
          searchForm,
          url: 'sysrole/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('sysrole:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('sysrole:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('sysrole:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('sysrole:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入主键id', trigger: 'blur'},],
            roleName :[
              {required: true, message: '请输入角色名称', trigger: 'blur'},],
            roleSign :[
              {required: true, message: '请输入角色标识', trigger: 'blur'},],
            userIdCreate :[
              {required: true, message: '请输入创建用户id', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
        },
        data: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      }
    },
    methods:{
        hasRole(val){
            return this.hasPerm(val)
        },
      initForm() {//初始数据
        return {
          id : "", // 主键id
          roleName : "", // 角色名称
          roleSign : "", // 角色标识
          remark : "", // 备注
          userIdCreate : "", // 创建用户id
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
        this.getAllMenuInfo()
        this.setCheckedKeys(row.id)
      },
      //获取所有菜单信息
      async getAllMenuInfo() {
          this.data=[]
          let res=await getAllMenu()
          if (res.status=="200"){
              this.data=res.data
          }else{
              MessageError(res.msg)
          }
      },
      //设置选择的菜单信息
      async setCheckedKeys(id) {
          let res=await getRoleMenulistById(id)
          if (res.status=="200"){
              this.$refs.tree.setCheckedKeys(res.data);
          }else {
              MessageError(res.msg)
          }
      },
      handleDelete(row) {
          this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              this.operatorDeleteUser(row)
          }).catch(() => {
              MessageInfo("已取消删除");
          });
      },
      async operatorDeleteUser(row) {
        let res = await deleteSysRole(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
        this.getAllMenuInfo()
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelSysRole(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
        this.$refs.tree.setCheckedKeys([])
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let loading=startLoading()
        let res;
        this.form.roleSign=this.$refs.tree.getCheckedKeys().toString()
        if (this.type == "insert") {
          res = await insertSysRole(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateSysRole(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        loading.close()
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
